import axios from "axios";

class coursesApi {
    async get({ page, limit, search }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .get(`/courses?page=${page}&limit=${limit}&search=${search}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async getAll() {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .get(`/courses/getAll`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async add({ courses_name, courses_note }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .post("/courses", {
                courses_name,
                courses_note,
            })
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async edit({ courses_id, courses_name, courses_note }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .put("/courses", {
                courses_id,
                courses_name,
                courses_note,
            })
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async remove(id) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .delete(`/courses/courses_id/${id}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }
}

export default new coursesApi();